import { ApolloProvider } from '@nerdwallet/apollo';
import getApolloClient from '@nerdwallet/apollo/client';
import { useExperiment } from '@nerdwallet/features';
import { useEffect } from 'preact/compat';

const ApolloWrapper = ({ children }) => {
  const apolloClient = getApolloClient();
  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>;
};

const PATH = '/article';
const CC_EXPERIMENT = 'cc-sticky-nav-test-may-2024';
const WEALTH_EXPERIMENT = 'wealth-sticky-nav-test-may-2024';

const ARTICLES = [
  { pattern: `${PATH}/credit-cards`, testName: CC_EXPERIMENT },
  { pattern: `${PATH}/travel`, testName: CC_EXPERIMENT },
  { pattern: `${PATH}/taxes`, testName: WEALTH_EXPERIMENT },
  { pattern: `${PATH}/banking`, testName: WEALTH_EXPERIMENT },
  { pattern: `${PATH}/investing`, testName: WEALTH_EXPERIMENT },
];

const HeaderExperimentInner = () => {
  const currentUrl = window.location.href;

  const matchedArticle = ARTICLES.find(({ pattern }) =>
    currentUrl.includes(pattern)
  );

  if (!matchedArticle) {
    return null;
  }

  const { testName } = matchedArticle;

  const { assignedVariantName, loading } = useExperiment({
    testName,
  });

  if (loading) return null;

  useEffect(() => {
    const header = document.querySelector('header');

    if (header) {
      const isHeaderSticky = assignedVariantName === 'Test';
      header.setAttribute('data-isHeaderSticky', `${isHeaderSticky}`);
    }
  }, [assignedVariantName]);

  return null;
};

const HeaderExperiment = () => {
  return (
    <ApolloWrapper>
      <HeaderExperimentInner />
    </ApolloWrapper>
  );
};
export default HeaderExperiment;
